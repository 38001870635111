import apiClient from '@/services/axios';

// Allergy
const addAllergy = async (data) => {
    const res = await apiClient.post('/api/patients/allergy', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getAllergy = async (patientId) => {
    const res = await apiClient.get(`/api/patients/allergy?patient_id=${patientId}`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const deleteAllergy = async (id) => {
    const res = await apiClient.delete(`/api/patients/allergy`, {
        data: {
            id: id,
        },
    })
    // console.log("res", res)
    if (res) {
        return res
    } else {
        return null
    }
}

// Illness History
const addIllnessHistory = async (data) => {
    const res = await apiClient.post('/api/patients/illness-history', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getIllnessHistory = async (patientId) => {
    const res = await apiClient.get(`/api/patients/illness-history?patient_id=${patientId}`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const deleteIllnessHistory = async (id) => {
    const res = await apiClient.delete(`/api/patients/illness-history`, {
        data: {
            id: id,
        },
    })
    // console.log("res", res)
    if (res) {
        return res
    } else {
        return null
    }
}

// Medication Statement
const addMedicationStatement = async (data) => {
    const res = await apiClient.post('/api/patients/medication-statement', data, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const getMedicationStatement = async (patientId) => {
    const res = await apiClient.get(`/api/patients/medication-statement?patient_id=${patientId}`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const deleteMedicationStatement = async (id) => {
    const res = await apiClient.delete(`/api/patients/medication-statement`, {
        data: {
            id: id,
        },
    })
    // console.log("res", res)
    if (res) {
        return res
    } else {
        return null
    }
}

export { 
    addAllergy, 
    getAllergy, 
    deleteAllergy, 
    addIllnessHistory,
    getIllnessHistory,
    deleteIllnessHistory,
    addMedicationStatement,
    getMedicationStatement,
    deleteMedicationStatement,
}