import apiClient from '@/services/axios'

const searchKeluhanUtama = async (keyword) => {
    const res = await apiClient.get(`/api/sct-codes/search/keluhan-utama?q=${keyword}&limit=10`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const searchRiwayatPribadi = async (keyword) => {
    const res = await apiClient.get(`/api/sct-codes/search/riwayat-pribadi?q=${keyword}&limit=30`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const searchRiwayatKeluarga = async (keyword) => {
    const res = await apiClient.get(`/api/sct-codes/search/riwayat-keluarga?q=${keyword}&limit=30`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

const searchAlergi = async (keyword) => {
    const res = await apiClient.get(`/api/sct-codes/search/alergi?q=${keyword}&limit=10`, {})
    if (res) {
        return res
    } else {
        return null
    }
}

export {
    searchKeluhanUtama,
    searchRiwayatPribadi,
    searchRiwayatKeluarga,
    searchAlergi,
}